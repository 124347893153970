import GeneralPagesLayout from 'src/layouts/GeneralPagesLayout';
import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme/UseTheme';
import useLang from '../../services/language/useLang';
import { useLocalize } from '../../services/localize/useLocalize';
import { ErrorStyled } from './Error.styles';

const NotFoundPage = () => {
  const lang = useLang();
  const localize = useLocalize();
  const theme = useTheme();

  return (
    <GeneralPagesLayout>
      <ErrorStyled theme={theme} className="Error">
        <div className="Error__imageBox">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="Error__image" src="/static/images/404.png" alt="404" />
        </div>
        <div className="Error__textBox">
          <div className="Error__number">404</div>
          <div className="Error__title">{localize('whoops_page_does_not_exist')}</div>
          <div className='Error__buttonWrapper'>
            <Button variant='primary' href={`/${lang}/`}>
              {localize('to_the_main')}
            </Button>
          </div>
        </div>
      </ErrorStyled>
    </GeneralPagesLayout>
  );
};
export default NotFoundPage;
